@import "src/styles/helpers";

.hero {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;

  @include media(tablet) {
    padding-top: 72px;
  }

  @include media(portrait) {
    padding-bottom: 96px;
  }

  @include media(mobile) {
    padding-top: 72px;
    padding-bottom: 48px;
  }

  &__image {
    margin-top: 80px;
    position: relative;

    @include media(mobile) {
      margin-top: 48px;
    }

    img {
      position: relative;
      z-index: 1;
    }
  }
}

.title {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.subtitle {
  font-size: 16px;
  max-width: 720px;
  line-height: 28px;
  margin: 24px auto 48px;
  text-wrap: balance;

  @include media(portrait) {
    font-size: 14px;
    line-height: 24px;
  }

  @include media(mobile) {
    font-size: 14px;
    line-height: 24px;
    margin-top: 16px;
  }
}

.sound {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  overflow: hidden;
  max-width: 100vw;
  position: absolute;
  bottom: calc(50% - 72px / 2);
  height: 72px;
  left: 0;
  right: 0;
  z-index: 0;

  &__bar {
    width: 8px;
    flex-shrink: 0;
    background: var(--primary-500);
    border-radius: 100px;
  }

  @for $i from 1 through 95 {
    $randomHeight1: random(62) + 10 + px;
    $randomHeight2: random(62) + 10 + px;
    $randomHeight3: random(62) + 10 + px;
    $randomHeight4: random(62) + 10 + px;

    .sound__bar:nth-child(#{$i}) {
      --random-height-1: #{$randomHeight1};
      --random-height-2: #{$randomHeight2};
      --random-height-3: #{$randomHeight3};
      --random-height-4: #{$randomHeight4};
      height: random(62) + 10 + px;
      animation: wave 2s linear infinite;

      opacity: 0.15;
    }
  }
}

@keyframes wave {
  25% {
    height: var(--random-height-2);
  }
  50% {
    height: var(--random-height-3);
  }
  75% {
    height: var(--random-height-1);
  }
}

.image {

  @include media(tablet) {
    display: none;
  }

  @include media(landscape) {
    width: calc(100% + 60px);
    margin-left: -30px;
    height: auto;
  }

  @include media(portrait) {
    width: 100%;
    height: auto;
  }

  @include media(mobile) {
    display: none;
    width: 100%;
    height: auto;
  }

  &_mobile {
    display: none;

    @include media(tablet) {
      display: block;
    }

    @include media(mobile) {
      display: block;
    }
  }
}

.button {

  @include media(mobile) {
    max-width: 327px;
    width: 100%;
  }
}

.vector1 {
  position: absolute !important;
  top: -40px;
  right: 600px;

  @include media(desktop) {
    right: 370px;
  }

  @include media(desktopSmall) {
    right: 270px;
  }

  @include media(tablet) {
    right: 220px;
  }

  @include media(mobile) {
    right: 70px;
  }
}

.vector2 {
  position: absolute !important;
  top: 100px;
  right: 380px;

  @include media(desktop) {
    right: 150px;
  }

  @include media(desktopSmall) {
    right: 50px;
  }

  @include media(tablet) {
    top: 50px;
    right: 0px;
  }

  @include media(mobile) {
    top: 0;
    right: -50px;
  }
}

.vector3 {
  position: absolute !important;
  bottom: -50px;
  right: 680px;

  @include media(desktop) {
    right: 450px;
  }

  @include media(desktopSmall) {
    right: 350px;
  }

  @include media(tablet) {
    right: 300px;
  }

  @include media(mobile) {
    bottom: -50px;
    right: 80px;
  }
}

.vector4 {
  position: absolute !important;
  bottom: 100px;
  left: 430px;

  @include media(desktop) {
    left: 200px;
  }

  @include media(desktopSmall) {
    left: 100px;
  }

  @include media(tablet) {
    left: 50px;
  }

  @include media(mobile) {
    bottom: 0;
    left: -50px;
  }
}

.vector5 {
  position: absolute !important;
  top: 130px;
  left: 380px;

  @include media(desktop) {
    left: 150px;
  }

  @include media(desktopSmall) {
    left: 50px;
  }

  @include media(tablet) {
    left: 0px;
    top: 80px;
  }

  @include media(mobile) {
    top: 20px;
    left: 0;
  }
}

.gradient {
  top: -70px;
  position: absolute !important;

  left: 50%;
  transform: translateX(-50%);

  width: 1250px;
  height: auto;

  z-index: -1 !important;

  @include media(mobile) {
    width: 100%;
  }
}