@import "src/styles/helpers";

.howItWorks {
  @include sectionPaddings;

  :global {
    .title {
      text-align: center;
      margin-bottom: 72px;

      @include media(mobile) {
        margin-bottom: 56px;
      }
    }
  }
}

.cards {
  display: grid;
  gap: 56px;

  @include media(portrait) {
    gap: 32px;
  }

  @include media(mobile) {
    gap: 24px;
  }
}

.card {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr 500px;
  gap: 64px;
  text-align: center;
  padding: 63px;
  background-color: var(--primary-50);
  border-radius: 24px;

  &:nth-child(even) {
    background-color: var(--primary-10);
  }

  @include media(landscape) {
    grid-template-columns: 1fr 394px;
    padding: 32px;
  }

  @include media(portrait) {
    grid-template-columns: 1fr !important;
    padding: 23px;
    gap: 48px;

    .card__info {
      order: 1;
    }
  }

  @include media(mobile) {
    grid-template-columns: 1fr !important;
    gap: 32px;
    padding: 15px;

    .card__info {
      order: 1;
    }
  }

  @include media(mobile) {

  }

  &:nth-child(even) {
    grid-template-columns: 500px 1fr;

    @include media(landscape) {
      grid-template-columns: 394px 1fr;
    }

    .card__info {
      order: 1;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    letter-spacing: -0.81px;
    color: var(--primary-800);
    max-width: 80%;
    margin: 0 auto 16px;

    @include media(tablet) {
      font-size: 20px;
      margin-bottom: 24px;
    }

    @include media(portrait) {
      margin-bottom: 16px;
    }

    @include media(mobile) {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.6px;
      margin-bottom: 12px;
      max-width: none;
    }
  }

  &__text {
    margin-bottom: 24px;
    line-height: 28px;
    color: var(--primary-800);

    @include media(tablet) {
      font-size: 14px;
    }

    @include media(portrait) {
      line-height: 24px;
    }

    @include media(mobile) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__image {
    height: 400px;
    width: 100%;
    position: relative;
    border-radius: 16px;
    padding: 0 25px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(118.75px);
    @include imgContain;

    @include media(landscape) {
      height: 315px;
    }

    @include media(portrait) {
      height: 400px;
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 32px;
      padding-right: 32px;
    }

    @include media(mobile) {
      height: 236px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__link {
    line-height: 28px;
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
}
