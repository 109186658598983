@import 'src/styles/helpers';

.assistants {
  padding-top: 96px;
  padding-bottom: 96px;
  position: relative;

  @include media(mobile) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  :global {

    .title {
      max-width: 650px;
      margin-bottom: 96px;

      @include media(mobile) {
        margin-bottom: 56px;
      }
    }
  }
}

.content {
  display: grid;
  gap: 64px;

  @include media(portrait) {
    gap: 48px;
  }

  @include media(mobile) {
    gap: 48px;
  }
}

.assistant {
  display: grid;
  align-items: flex-start;
  position: relative;
  border-radius: 24px;
  padding: 48px;

  @include media(landscape) {
    padding: 24px;
  }

  @include media(portrait) {
    grid-template-columns: 1fr !important;
    padding: 0 24px 24px;
    gap: 24px !important;

    &__photo {
      height: 374px;
      position: relative !important;
      margin-top: -40px;
      margin-left: auto;
      margin-right: auto;
      left: 0 !important;

      img {
        height: 100%;
        width: auto;
      }
    }

    &__cover {
      position: absolute !important;
      top: 270px;
      right: auto;
      left: 60%;
    }
  }

  @include media(mobile) {
    grid-template-columns: 1fr !important;
    padding: 0 16px 24px;
    gap: 24px !important;

    &__photo {
      height: 287px;
      position: relative !important;
      margin-top: -40px;
      margin-left: auto;
      margin-right: auto;
      left: 0 !important;

      img {
        height: 100%;
        width: auto;
      }
    }

    &__cover {
      position: absolute !important;
      top: 195px;
      right: 34px;
    }
  }

  &:nth-child(odd) {
    gap: 66px;
    background: var(--primary-700);
    grid-template-columns: 1fr 550px;

    @include media(desktopSmall) {
      gap: 52px;
      grid-template-columns: 1fr 500px;
    }

    @include media(landscape) {
      grid-template-columns: 1fr 400px;
      gap: 68px;
    }

    .assistant {

      &__photo {
        left: 55px;

        @include media(landscape) {
          width: auto;
          height: 490px;
          left: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .assistant__person {
      background: var(--primary-200);
      color: var(--primary-900);
    }

    .assistant__title {
      color: var(--secondary-10);
    }

    .assistant__text {
      color: var(--primary-50);
    }

    .impact {
      &__title, &__icon {
        color: var(--primary-200);
      }

      &__text {
        color: var(--primary-10);
      }
    }
  }

  &:nth-child(even) {
    background: var(--primary-200);
    grid-template-columns: 550px 1fr;
    gap: 49px;

    @include media(desktopSmall) {
      gap: 69px;
      grid-template-columns: 445px 1fr;
    }

    @include media(landscape) {
      grid-template-columns: 400px 1fr;
      gap: 37px;
    }

    .assistant {

      &__photo {
        right: 48px;

        @include media(landscape) {
          width: auto;
          height: 485px;
          right: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &__cover {
        order: 1;
        justify-content: flex-start;
      }
    }

    .assistant__person {
      background: var(--primary-700);
      color: var(--primary-50);
    }

    .assistant__title, .assistant__text {
      color: var(--primary-800);
    }

    .impact {
      &__title, &__icon {
        color: var(--primary-700);
      }

      &__text {
        color: var(--primary-900);
      }
    }
  }

  &__cover {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: flex-end;
  }

  &__person {
    position: relative;
    z-index: 1;
    padding: 16px 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;

    @include media(portrait) {
      padding: 12px 16px;
    }

    @include media(mobile) {
      padding: 8px 16px;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: bold;

    @include media(portrait) {
      font-size: 16px;
    }

    @include media(mobile) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__description {
    font-size: 14px;

    @include media(portrait) {
      font-size: 12px;
    }

    @include media(mobile) {
      white-space: nowrap;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__photo {
    position: absolute;
    bottom: 0;

    img {
      display: block;
    }
  }

  &__content {
    display: grid;
    align-items: flex-start;
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    letter-spacing: -0.84px;
    margin-bottom: 16px;

    @include media(tablet) {
      font-size: 24px;
      letter-spacing: -0.72px;
      line-height: 32px;
    }

    @include media(mobile) {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.6px;
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;
    margin-bottom: 16px;
  }

  &__text {
    line-height: 28px;
    margin-bottom: 24px;

    @include media(tablet) {
      font-size: 14px;
      line-height: 24px;
    }

    @include media(landscape) {
      margin-bottom: 16px;
    }

    @include media(portrait) {
      margin-bottom: 24px;
    }

    @include media(mobile) {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  &__voice {
    width: 100%;
    margin-bottom: 48px;
    height: 48px;
  }

  .impact {
    border-radius: 24px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 8px;
    color: var(--primary-50);
    align-self: flex-end;

    &__icon {
      width: 64px;
      height: 64px;

      @include media(mobile) {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
      }
    }

    &__title {
      margin-bottom: 4px;
      font-weight: bold;
      line-height: 24px;

      @include media(mobile) {
        font-size: 14px;
      }
    }

    &__text {
      font-size: 12px;
      line-height: 16px;
      color: var(--secondary-100);

      @include media(mobile) {
        font-size: 12px;
      }
    }
  }
}

.player {
  margin-bottom: 48px;

  @include media(portrait) {
    margin-bottom: 32px;
  }

  @include media(mobile) {
    margin-bottom: 32px;
  }
}

.vector {
  width: 484px;
  height: 484px;
  transform: rotate(30.59deg);
  position: absolute;
  pointer-events: none;
  user-select: none;
  right: -301px;
  top: -240px;
  opacity: 0.15;
  z-index: 2;

  @include media(desktopBig) {
    right: -201px;
    top: -200px;
  }

  @include media(desktopSmall) {
    right: -371px;
    top: -170px;
  }

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
