@import "src/styles/helpers";

.benefits {
  @include sectionPaddings;

  :global {

    .title {
      max-width: 748px;
      margin-bottom: 72px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 394px 1fr;
    align-items: stretch;
    gap: 16px;

    @include media(landscape) {
      grid-template-columns: 362px 1fr;
    }

    @include media(portrait) {
      grid-template-columns: 1fr;
      gap: 0;
    }

    @include media(mobile) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
}

.sidebar {
  display: grid;

  @include media(portrait) {
    order: 1;
  }

  @include media(mobile) {
    order: 1;
  }

  .button {
    color: var(--primary-700);
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;
    font-weight: bold;
    background: var(--primary-50);
    padding: 32px 82px 32px 32px;
    display: block;
    width: 100%;
    position: relative;

    &:hover {
      color: var(--secondary-10);
      background: var(--primary-700);
    }

    @include media(landscape) {
      padding: 24px 62px 24px 24px;
      font-size: 16px;
    }

    @include media(portrait) {
      font-size: 16px;
      line-height: 24px;
      padding: 24px 62px 24px 24px;
      letter-spacing: -0.48px;
    }

    @include media(mobile) {
      font-size: 16px;
      line-height: 24px;
      padding: 24px 62px 24px 24px;
      letter-spacing: -0.48px;
    }

    &:first-of-type {
      border-radius: 16px 16px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 16px 16px;

      @include media(portrait) {
        &.active {
          border-radius: 0;
        }
      }

      @include media(mobile) {
        &.active {
          border-radius: 0;
        }
      }
    }

    &:not(:first-of-type) {
      border-top: 1px solid var(--primary-100);
    }

    &:focus {
      z-index: 1;
    }

    &.active {
      color: var(--secondary-10);
      background: var(--primary-700);

      .dropdownIcon {
        transform: none;
      }

      + .benefit_mobile {
        @include media(portrait) {
          display: grid;
        }

        @include media(mobile) {
          display: grid;
        }
      }
    }

    .dropdownIcon {
      position: absolute;
      top: calc(50% - 12px);
      right: 32px;
      width: 24px;
      height: 24px;
      transition: transform .3s ease;
      transform: rotate(90deg);

      @include media(tablet) {
        right: 24px;
      }

      @include media(mobile) {
        right: 24px;
      }
    }
  }
}

.content {
  background: var(--primary-700);
  border-radius: 16px;
  padding: 48px;
  height: 636px;

  @include media(landscape) {
    padding: 24px;
    height: 517px;
  }

  @include media(portrait) {
    border-radius: 16px 16px 0 0;
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.benefit {
  display: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  gap: 64px 56px;
  transition: all .2s ease;
  height: 100%;

  @include media(landscape) {
    gap: 48px 44px;
  }

  &.active {
    display: grid;
    animation: scaleUp .2s forwards;
  }

  &.startHiding {
    animation: scaleDown .2s forwards;
  }
}


@keyframes scaleUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  to {
    transform: scale(0);
  }
}

.image {
  position: relative;
  height: 340px;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  grid-column: 1 / 3;
  @include imgCover;
  align-self: flex-end;

  @include media(landscape) {
    height: 224px;
  }

  @include media(portrait) {
    height: 316px;
  }

  @include media(mobile) {
    grid-column: auto;
    margin-top: 24px;
    height: 156px;
    width: 100%;
  }
}

.column {

  @include media(mobile) {
    &:not(:first-of-type) {
      margin-top: 32px;
    }
  }

  &_fullWidth {
    grid-column: 1 / 3;

    @include media(mobile) {
      grid-column: auto;
    }
  }

  &__title {
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
    color: var(--primary-10);

    @include media(portrait) {
      font-size: 14px;
    }

    @include media(mobile) {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-10);
  }
}

.benefit_mobile {
  display: none;
  background: var(--primary-700);
  padding: 24px;

  @include media(portrait) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 48px;
  }

  &:last-of-type {
    border-radius: 0 0 16px 16px;
  }
}
