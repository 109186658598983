@import 'src/styles/helpers';

.cta {
  position: relative;
  padding-bottom: 104px;
  width: 100vw;
  text-align: center;

  @include media(landscape) {
    padding-bottom: 96px;
  }

  @include media(portrait) {
    padding-bottom: 72px;
  }

  @include media(mobile) {
    padding-bottom: 56px;
  }

  &__button {
    margin: 41px auto 0;
    display: inline-block;
    position: relative;

    @include media(desktopSmall) {
      margin-top: 32px;
    }

    @include media(mobile) {
      margin-top: 27px;
      width: 100%;
      max-width: 327px;
    }
  }

  &__text {
    position: relative;
    z-index: 0;
    background-color: var(--primary-200);
    background-image: var(--linear-hero);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 8vw;
    letter-spacing: -0.33vw;
    opacity: 0.8;
    flex-shrink: 0;
    padding: 0 70px;
    animation-name: marqueeLine;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @include media(mobile) {
      padding: 0 16px;
      animation-duration: 5s;
    }
  }
}

@keyframes marqueeLine {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.marquee {
  display: flex;
  overflow: hidden;
  width: 100vw;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  }
}

.buttonBlock {
  position: relative;
  z-index: 1;

  @include media(mobile) {
    text-align: center;
    padding: 0 24px;
  }

  .blur {
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
    width: 900px;
    height: 60px;
    border-radius: 898px;
    background: radial-gradient(50% 50% at 50% 50%, #004c6c 0%, rgba(0, 173, 239, 0) 100%);
    filter: blur(82.1px);
  }
}

.vector1 {
  position: absolute !important;
  top: -100px;
  right: 600px;

  @include media(desktop) {
    right: 370px;
  }

  @include media(desktopSmall) {
    right: 0px;
  }

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.vector2 {
  position: absolute !important;
  bottom: -50px;
  right: 700px;

  @include media(desktop) {
    right: 470px;
  }

  @include media(desktopSmall) {
    right: 200px;
  }

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.vector3 {
  position: absolute !important;
  bottom: 50px;
  left: 200px;

  @include media(desktop) {
    left: 130px;
  }

  @include media(desktopSmall) {
    left: 20px;
  }

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}