@import "src/styles/helpers";

.illustrationWrap {
  position: relative;
  max-width: 100vw;
  // overflow: hidden;
}

.vector {
  width: 377px;
  height: 377px;
  transform: rotate(-26.589deg);
  position: absolute;
  pointer-events: none;
  user-select: none;
  right: -180px;
  top: 210px;
  opacity: 0.15;
  z-index: 2;

  @include media(desktopBig) {
    right: -130px;
    top: 320px;
  }

  @include media(desktopSmall) {
    top: 170px;
    right: -300px;
  }

  @include media(tablet) {
    top: 170px;
    right: -280px;
  }

  @include media(mobile) {
    top: 60px;
    right: -300px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
