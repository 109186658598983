@mixin sectionPaddings {
  padding-top: 120px;
  padding-bottom: 120px;

  @include media(tablet) {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  @include media(mobile) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
