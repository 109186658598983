@import 'src/styles/helpers';

.track {
  border-radius: 24px;
  padding: 11px 24px;
  background: var(--primary-10);
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto 1fr 40px;
  color: var(--primary-700);
  gap: 12px;

  @include media(mobile) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.playButton {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
    color: var(--primary-600);
    transition: color .3s ease;
  }

  &:hover svg {
    color: var(--primary-200);
  }
}

.progress {
  background-color: rgba(#00293E, 0.66);
  width: 100%;
  height: 32px;
  mask-image: url('/media/track_mask.svg');

  .progressBar {
    background: var(--primary-600);
    height: 100%;
  }
}

.time {
  color: rgba(#0D1D27, 0.66);
  text-align: right;
}

.track.darkMode {
  background: var(--primary-600);
  color: var(--primary-10);

  .playButton svg {
    color: var(--secondary-100);
  }

  .progress {
    background-color: var(--secondary-100);

    .progressBar {
      background: var(--primary-700);
    }
  }
}
