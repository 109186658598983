@import "src/styles/helpers";

.opportunities {
  @include sectionPaddings;

  :global {

    .title {
      max-width: 698px;
      margin-bottom: 72px;

      @include media(landscape) {
        max-width: 550px;
      }

      @include media(portrait) {
        max-width: 540px;
      }

      @include media(mobile) {
        margin-bottom: 56px;
      }
    }
  }
}

.card {
  display: grid;
  padding: 40px 56px;
  border-radius: 24px;
  background: var(--primary-10);

  @include media(tablet) {
    padding: 24px;
  }

  @include media(mobile) {
    padding: 24px 16px;
  }

  &:not(.card_fullWidth) {
    text-align: center;

    .card__title {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &_fullWidth {
    grid-column: 1 / 3;
    display: grid;
    justify-content: space-between;
    align-items: stretch;
    grid-template-columns: repeat(2, 476px);
    gap: 0 32px;
    background: var(--primary-50);

    @include media(desktopBig) {
      grid-template-columns: repeat(2, 516px);
    }

    @include media(desktopSmall) {
      gap: 0 56px;
      grid-template-columns: repeat(2, 444px);
    }

    @include media(landscape) {
      grid-template-columns: repeat(2, 388px);
    }

    @include media(portrait) {
      grid-column: auto;
      grid-template-columns: 1fr;
      text-align: center;
    }

    @include media(mobile) {
      grid-template-columns: 1fr;
      grid-column: auto;
      text-align: center;
    }

    .card {

      &__title, &__text, &__icon {
        grid-column: 1 / 2;
      }

      &__title {
        text-align: center;
        max-width: none;
      }

      &__text {
        text-align: center;

        @include media(portrait) {
          margin-bottom: 32px;
        }

        @include media(mobile) {
          margin-bottom: 16px;
        }
      }

      &__image {
        grid-row: 1 / 4;
        grid-column: 2 / 3;
        align-self: center;

        @include media(portrait) {
          grid-row: auto;
          grid-column: auto;
        }

        @include media(mobile) {
          grid-row: auto;
          grid-column: auto;
        }
      }
    }
  }

  &__icon {
    width: 96px;
    height: 96px;
    border-radius: 16px;
    padding: 8px;
    background: var(--primary-100);
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }

    @include media(tablet) {
      width: 72px;
      height: 72px;
    }

    @include media(mobile) {
      width: 72px;
      height: 72px;
    }
  }

  &__title {
    margin: 32px 0 16px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;
    color: var(--primary-600);
    max-width: 65%;

    @include media(tablet) {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.48px;
    }

    @include media(mobile) {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.48px;
      margin: 24px 0 12px;
      max-width: none;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: var(--secondary-700);

    &:not(.card_fullWidth &) {
      margin-bottom: 32px;

      @include media(mobile) {
        margin-bottom: 16px;
      }
    }
  }

  &__image {
    position: relative;
    align-self: flex-end;

    @include media(portrait) {
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media(mobile) {
      max-width: 295px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include media(portrait) {
    grid-template-columns: 1fr;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
  }
}
