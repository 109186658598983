@import 'src/styles/helpers';

.audio {
  padding: 72px 72px 140px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 550px 1fr;
  gap: 92px 34px;
  border-radius: 24px;
  overflow: hidden;
  background: url('/media/audio_wave.svg') no-repeat center / cover var(--primary-700);

  @include media(tablet) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 24px;
    margin-top: 24px;
  }

  @include media(landscape) {
    padding: 48px 48px 72px;
  }

  @include media(portrait) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 32px 32px 64px;
    gap: 72px 34px;
  }

  @include media(mobile) {
    padding: 24px;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .contactButton {
    background: var(--primary-10);
    color: var(--primary-500);

    &:hover,
    &:focus {
      background: var(--primary-100);
    }

    &_mobile {
      display: none;
    }

    @include media(mobile) {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}

.title {
  font-size: 36px;
  letter-spacing: -0.72px;
  font-weight: bold;
  margin-bottom: 48px;
  text-wrap: balance;
  color: var(--secondary-10);

  @include media(tablet) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }

  @include media(mobile) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }
}

.subtitle {
  color: var(--primary-10);
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 24px;
}

.text {
  line-height: 24px;

  @include media(tablet) {
    font-size: 14px;
  }

  @include media(mobile) {
    font-size: 14px;
    line-height: 24px;
  }
}

.playButton {
  margin-left: 6px;
  margin-right: 6px;
  transition: all 0.3s ease;
  color: var(--primary-10);
  position: relative;
  border-radius: 50%;
  width: 60px;
  height: 60px;

  @include media(mobile) {
    width: 52px;
    height: 52px;
  }

  &:hover {
    color: var(--secondary-10);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.info {
  display: grid;
  gap: 16px;
  align-self: flex-start;
  color: var(--primary-10);

  @include media(mobile) {
    gap: 12px;
  }
}

.playBlock {
  text-transform: uppercase;
  text-align: center;
  grid-column: 1 / 3;
  color: var(--primary-50);

  @include media(mobile) {
    grid-column: auto;
    margin-top: 44px;
  }

  &__text {
    transition: all 0.3s ease;
    position: relative;

    @include media(tablet) {
      font-size: 14px;
    }

    @include media(mobile) {
      font-size: 12px;
    }

    &.hidden {
      animation: hideText 0.2s linear forwards;
    }

    @keyframes hideText {
      to {
        opacity: 0;
      }
    }
  }

  &__soundBar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: 72px;
    margin-bottom: 12px;
    position: relative;

    @include media(mobile) {
      margin-bottom: 8px;
    }

    .ellipses {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      width: 800px;
      height: 600px;
      animation: show 0.2s linear forwards;
      opacity: 0;
      pointer-events: none;
      user-select: none;

      @include media(mobile) {
        width: 400px;
        height: 250px;
      }

      &.hidden {
        animation: hide 0.2s linear forwards;
        opacity: 1;
      }

      @keyframes show {
        to {
          opacity: 1;
        }
      }

      @keyframes hide {
        to {
          opacity: 0;
        }
      }
    }

    .soundBar {
      width: 8px;
      flex-shrink: 0;
      background: var(--secondary-10);
      border-radius: 100px;
      animation: wave 2s linear infinite;
      position: relative;
    }

    &.paused .soundBar {
      animation: waveFadeOut 0.3s linear forwards;
    }

    @for $i from 1 through 12 {
      $randomHeight1: random(62) + 10 + px;
      $randomHeight2: random(62) + 10 + px;
      $randomHeight3: random(62) + 10 + px;
      $randomHeight4: random(62) + 10 + px;

      .soundBar:nth-child(#{$i}) {
        --random-height-1: #{$randomHeight1};
        --random-height-2: #{$randomHeight2};
        --random-height-3: #{$randomHeight3};
        --random-height-4: #{$randomHeight4};
        height: 8px;
      }
    }

    @keyframes wave {
      0% {
        height: var(--random-height-4);
      }
      25% {
        height: var(--random-height-2);
      }
      50% {
        height: var(--random-height-3);
      }
      75% {
        height: var(--random-height-1);
      }
      100% {
        height: var(--random-height-4);
      }
    }
  }
}
